import React from 'react';
import './App.css';
import QrCodeForms from "./components/QrCodeForms";
import {Col, Container, Image, Row} from "react-bootstrap";
import logo from "./images/logo_fl.png";
import logoSmdya from "./images/smdya.png";

function App() {
  return (
      <div>
          <Container className="p-3 my-5">
              <Row>
                  <Col md="6">
                      <Image src={logo}  className="img-fluid"/>
                  </Col>
                  <Col md="6">
                      <Image src={logoSmdya} className="img-fluid"/>
                  </Col>
              </Row>
          </Container>
          <Container className="p-3">
              <h1 className="header text-center display-4">
                  Музыкальный семестр мира и дружбы
              </h1>
              <div className="mb-xl-5 text-center">
                  <h3 className="display-6">
                      Заполните форму и получите Qr код для участия в проекте
                  </h3>
              </div>
              <div className="my-4">
                  <QrCodeForms/>
              </div>
          </Container>
          <Container>
              <Row>
                  <div>
                      <div className="display-6 text-center my-5" >Проект «Музыкальный семестр мира и дружбы» проводится для студенческой молодежи города Якутска.</div>
                      <div className="mb-3 fs-5">
                          <p>
                               Данный проект является победителем конкурса Грантов Главы Республики Саха (Якутия) 2024 года. Цель проекта – организация и проведение в течение семестра 10 концертных мероприятий, посещение которых будет отмечено в специальной (электронной) зачетной книжке.
                          </p>
                          <p>
                              По завершении семестра среди студентов, посетивших 10 концертов, пройдет конкурс зачеток. В нем будет случайным образом выбрано 10 участников, которые получат в качестве награды годовые абонементы на концерты в новом акустическом зале Государственной филармонии РС (Я) им. Г.М. Кривошапко, расположенном в здании Арктического центра эпоса и искусств. Абсолютный победитель, посетивший все концерты семестра, получит авиабилет и путевку в Санкт-Петербург с культурной программой (спектакль и концерт в Мариинском театре, концерт в Санкт-Петербургской филармонии, экскурсия в Эрмитаж и Русский музей).
                          </p>
                          <p className="text-center fs-5"><strong>
                              Надеемся на ваше активное участие в проекте!
                          </strong>
                          </p>
                      </div>
                      <div className="mb-5 fs-5">
                          Контактные данные:<br/>
                          г. Якутск. Ул. Ярославского, 27, оф. 111.
                          Тел. 8 (4112) 45-06-98, 47-63-35.
                          e-mail: gf@gov14.ru, market@filarmony.ru<br/>
                          По всем вопросам можно обращаться к администраторам: Габышевой Кюннэй Александровне, Горбуновой Алёне Игоревне, координатору по работе со студентами - Филипповой Варваре Васильевне.
                      </div>
                  </div>
              </Row>
              <Row>
                  <div>
                      <div className="display-6 text-center my-3">
                          Правила участия в проекте и регламент регистрации:
                      </div>
                      <div className="fs-5 mb-5">
                          <p>1. Получить персональный qr-код, скачать его или сфотографировать.
                          </p>
                          <p>2. С 19 сентября по 27 декабря 2024 г. посетить любых 10 концертов Филармонии Якутии.
                          </p>
                          <p>3. При посещении концертов предъявлять контролерам персональный qr-код, действительный студенческий билет и билет на концерт.
                          </p>
                          <p>4. Зачетная книжка «Музыкального семестра» будет формироваться автоматически.
                          </p>
                          <p>5. Объявление итогов состоится на заключительном Новогоднем концерте в КЦ СВФУ «Сергеляхские огни» 27 декабря 2024 г.
                          </p>
                      </div>
                      <div className="text-center fs-5 mb-5">
                          Следите за афишей Филармонии Якутии и формируйте свой «Музыкальный семестр"!
                      </div>
                  </div>
              </Row>
          </Container>
          <Container fluid className="footer">
              <Row >
                  <Col className="p-5 ">
                      <div>
                          <h6>АУ "Государственная филармония РС(Я) им. Г.М.Кривошапко".</h6>
                      </div>
                  </Col>
                  <Col className="p-3" lg="2">
                      <div>
                          Адрес<br/>
                          Республика Саха (Якутия)<br/>
                          город Якутск<br/>
                          ул. Ярославского, 27<br/>
                          gf@gov14.ru<br/>
                      </div>
                  </Col>
                  <Col className="p-3" lg="2">
                      <div>
                          Касса<br/>
                          (4112) 47-63-35, 45-06-98
                      </div>
                  </Col>
              </Row>
              <Row>
                  <Col></Col>
                  <Col lg="4">
                      <div className="footerNav">
                          <ul>
                              <li>
                                  <a href="https://filarmony.ru">Главная</a>
                              </li>
                              <li>
                                  <a href="https://filarmony.ru/ru/content/gosudarstvennaya-filarmoniya-respubliki-sakha-yakutiya-im-g-m-krivoshapko">О нас</a>
                              </li>
                              <li>
                                  <a href="https://filarmony.ru/ru/afisha">Афиша</a>
                              </li>
                              <li>
                                  <a href="https://filarmony.ru/ru/contact">Подать обращение</a>
                              </li>
                          </ul>
                      </div>
                  </Col>
              </Row>
          </Container>
      </div>
  );
}

export default App;
