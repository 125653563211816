import React, {FC, useRef, useState} from 'react';
import {Button, Card, CardBody, Col, Form, Row} from "react-bootstrap";
import {QRCodeCanvas, } from "qrcode.react";

const QrCodeForms:FC = () => {
    const [vCardData, setVCardData] = useState({
        firstName: "",
        lastName: "",
        email: "",
        phone: "",
        organization: "",
    });

    const generateVCardData = () => {
        const vCardsJS = require("vcards-js");
        const vCardString = vCardsJS();
        vCardString.firstName = vCardData.firstName;
        vCardString.lastName = vCardData.lastName;
        vCardString.email = vCardData.email;
        vCardString.cellPhone = vCardData.phone;
        vCardString.organization = vCardData.organization;
        //console.log(vCardString.getFormattedString());
        return vCardString.getFormattedString();
    };

    const handleChange = (name: string, event: any) => {
        const { value } = event.target;
        setVCardData((prevData) => ({ ...prevData, [name]: value }));
    };

    // Validate
    const [validated, setValidated] = useState(false);

    const handleSubmit = (event: any) => {
        const form = event.currentTarget;
        if (form.checkValidity() === false) {
            event.preventDefault();
            event.stopPropagation();
        }
        if(form.checkValidity() === true){
            onCanvasButtonClick();
        }
        setValidated(true);
        //console.log(form.checkValidity())
    };

    // Check answer
    const [check, setCheck] = useState(true)

    const checkChange = () => {
        setCheck(!check);
        console.log(check);
    }

    // Download
    const canvasRef = useRef<HTMLCanvasElement>(null);
    function downloadStringAsFile(data: string, filename: string) {
        let a = document.createElement('a');
        a.download = filename;
        a.href = data;
        a.click();
    }
    function onCanvasButtonClick() {
        const node = canvasRef.current;
        if (node == null) {
            return;
        }
        // For canvas, we just extract the image data and send that directly.
        const dataURI = node.toDataURL('image/png');

        downloadStringAsFile(dataURI, 'qrcode-canvas.png');
    }

    return (
        <>
            <Form noValidate validated={validated} onSubmit={handleSubmit}>
                <Row className="justify-content-md-center">
                    <Col lg="5">
                        <Form.Group className="mb-3" controlId="formBasicCheckbox">
                        <Form.Check type="checkbox" label="Я согласен на обработку персональных данных"
                                    required
                                    feedback="Вы должны согласиться на обработку данных"
                                    feedbackType="invalid"
                                    onChange={e=> (checkChange())}
                        />
                    </Form.Group>

                        <Form.Group className="mb-3" controlId="formFirstName">
                            <Form.Label>Имя</Form.Label>
                            <Form.Control type="text" placeholder="Введите имя"
                                          required
                                          disabled={check}
                                          onChange={e => handleChange("firstName", e)}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formLastName">
                            <Form.Label>Фамилия</Form.Label>
                            <Form.Control type="text" placeholder="Введите Фамилию"
                                          required
                                          disabled={check}
                                          onChange={e => handleChange("lastName", e)}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formPhonel">
                            <Form.Label>Телефон</Form.Label>
                            <Form.Control type="text" placeholder="Введите номер телефона"
                                          required
                                          disabled={check}
                                          onChange={e => handleChange("phone", e)}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formEmail">
                            <Form.Label>Email address</Form.Label>
                            <Form.Control type="email" placeholder="Введите email"
                                          required
                                          disabled={check}
                                          onChange={e => handleChange("email", e)}/>
                        </Form.Group>

                        <Form.Group className="mb-3" controlId="formOrganization">
                            <Form.Label>Учебное заведение</Form.Label>
                            <Form.Control as="textarea" placeholder="Введите название учебного заведения" rows={3}
                                          required
                                          disabled={check}
                                          onChange={e => handleChange("organization", e)}/>
                        </Form.Group>
                    </Col>

                    <Col lg="4">
                        <div>
                            <Card style={{width: '100%'}}>
                                <CardBody>
                                    <Card.Title>QR-код.</Card.Title>
                                    <Card.Text style={{textAlign: "center"}} className="qrCard h5 mb-3">
                                        {vCardData.firstName &&
                                        vCardData.lastName &&
                                        vCardData.email &&
                                        vCardData.phone &&
                                        vCardData.organization &&
                                        !check ? (
                                            <div>
                                                <QRCodeCanvas ref={canvasRef}
                                                              value={generateVCardData()}
                                                              size={260}
                                                              style={{marginTop: "10px"}}
                                                              level={"L"}
                                                              marginSize={3}
                                                />
                                            </div>
                                        ) : (
                                            <>
                                                Заполните форму
                                            </>
                                        )}
                                    </Card.Text>
                                    <Button variant="primary" type="submit">
                                        Скачать
                                    </Button>
                                </CardBody>
                            </Card>
                        </div>
                    </Col>
                </Row>
            </Form>
        </>
    );
};

export default QrCodeForms;